<template>
  <div class="side" v-if="$route.fullPath != '/theListedWebsites'">
    <div class="sidebox box1">
      <img class="numbers" src="@/assets/img/haoma.png" />
      <span class="numbersText">0755-28511630</span>
      <img src="@/assets/img/kefu@2x.png" />
      <div class="content">在线客服</div>
    </div>
    <div class="sidebox box2">
      <img class="officialAccount" src="@/assets/img/gongzhonghao@2x.png" />
      <img src="@/assets/img/weixin@2x.png" />
      <div class="content">官方微信</div>
    </div>
    <div class="sidebox box3">
      <img class="numbers" src="@/assets/img/haoma.png" />
      <span class="numbersText">0755-28511630</span>
      <img src="@/assets/img/hezuo@2x.png" />
      <div class="content">合作咨询</div>
    </div>
    <div class="sidebox box4" id="GoTop" @click="GoTop()">
      <img src="@/assets/img/goTop.png" />
      <div class="content">回到顶部</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Side",
  methods: {
    GoTop() {
      (function smoothscroll() {
        var currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 10);
        }
      })();
    },
    collaborateClick() {
      this.$router.push({
        name: "cooperationConsultation",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.side {
  float: right;
  opacity: 1;
  z-index: 999;
  position: sticky;
  top: 247px;
  right: 38px;

  // right: 100px;
  // margin-top: 247px;
  // margin-right: 38px;
  .sidebox {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    background: #357bff;
    border-radius: 4px;
    margin-bottom: 7px;

    .content {
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
  }

  .box1 {
    margin-top: 20px;
    position: relative;

    img {
      margin-top: 9px;
      width: 29px;
      height: 25px;
    }

    .numbers {
      display: none;
      position: absolute;
      right: 96px;
      width: 199px;
      height: 58px;
    }

    .numbersText {
      display: none;
      position: absolute;
      right: 96px;
      top: 33px;
      font-size: 18px;
      width: 199px;
      text-align: center;
      color: #333333;
    }
  }

  .box1:hover {
    .numbers {
      display: block;
    }

    .numbersText {
      display: inline-block;
    }
  }

  .box2 {
    position: relative;

    img {
      margin-top: 1px;
      width: 31px;
      height: 26px;
    }

    .officialAccount {
      position: absolute;
      top: 13px;
      right: 96px;
      width: 153px;
      height: 145px;
      display: none;
    }
  }

  .box2:hover {
    .officialAccount {
      display: block;
    }
  }

  .box3 {

    // cursor: pointer;
    img {
      margin-top: 1px;
      width: 32px;
      height: 26px;
    }

    .numbers {
      display: none;
      position: absolute;
      right: 96px;
      width: 199px;
      height: 58px;
    }

    .numbersText {
      display: none;
      position: absolute;
      right: 96px;
      font-size: 18px;
      width: 199px;
      text-align: center;
      color: #333333;
    }
  }

  .box3:hover {
    .numbers {
      display: block;
    }

    .numbersText {
      display: inline-block;
    }
  }

  .content {
    margin-top: 9px;
  }

  .box4 {
    cursor: pointer;
    width: 84px;
    height: 84px;
    background: #357bff;
    border-radius: 4px;

    img {
      margin: 0 auto;
      padding-top: 5px;
    }

    .content {
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
  }
}
</style>